import { Component } from '@angular/core';

@Component({
  selector: 'app-about',
  template: `
            <div>
                <h1>Page not found</h1>
            </div>
  `
})
export class AboutComponent {
  title = 'qr-generator';
}
